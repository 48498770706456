<template>
  <div class="menuTemplate">
    <featuredParent v-if="featuredScreen" :featuredScreen="featuredScreen" :playerInstance="playerInstance"></featuredParent>
    <div class="home-slider-up-parent">
        <div :class="['home-slider-view', !featuredScreen ? 'home-slider-view-withoutFeature' : '']" v-if="categoryScreens.length > 0">
          <div v-for="(item, index) in categoryScreens" :key="index" >
            <continueSlider
              v-if="(item.sectionType === 'CONTINUEWATCH' || item.sectionType === 'CONTINUEWATCHING') && (item.status == 'ACTIVE' || item.isSectionEnabled) && subscriberid"
              :screen="item"
              :playerInstance="playerInstance"
              :screenId="screenId"
              :counterTextV="index"
              :screenName="getIdValue(item.title.eng) + index"
              :id="getIdValue(item.title.eng) + '-' + index"
            ></continueSlider>
            <Slider
              v-if="
                (item.sectionType !== 'CONTINUEWATCH' && item.sectionType !== 'CONTINUEWATCHING')  &&
                  (item.isSectionEnabled || item.status == 'ACTIVE') &&
                  (!item.userTypes ||
                    (item.userTypes && item.userTypes.includes('ALL')) ||
                    (item.userTypes && item.userTypes.includes(getUserType())))
              "
              :screen="item"
              :id="getIdValue(item.title.eng) + '-' + index"
              :playerInstance="playerInstance"
              :counterTextV="index"
              :screenName="getIdValue(item.title.eng) + index"
              :screenId="screenId"
            ></Slider>
          </div>
        </div>
      </div> 
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";

export default {
  props: {
    screens: {
      type: Object,
    },
    menu: {
      type: [Object, String],
    },
    playerInstance: {
      type: Object,
    },
    screenId: {
      type: String,
    }
  },
  data() {
    return {
      enableTemplate: false,
      templates: [],
      featuredScreen: "",
      categoryScreens: [],
      localDisplayLang: null,
      showHoveredColor: false,
      subscriptions: [],   
    };
  },
  computed: {
    ...mapGetters([
      "subscriberid",
      "getToken",
      "getRtl",
      "subscriptionList",
      "getFirebaseUserUID",
      "appConfig"
    ]),
  },
  watch: {
    subscriberid(val) {
      if (val) {
        this.renderTemplate();
      }
    },
    subscriptionList(val) {
      if (val) {
        this.subscriptions = val;
      }
    },
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.localDisplayLang = this.localDisplayLang == 'ml' ? 'mal' : this.localDisplayLang;

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });

    eventBus.$on("rerender-template", () => {
      //fetching screens of that particular menu.
      this.renderTemplate();
      //toggling the menu filter component.
      this.renderMenuFilter();
    });

    //fetching screens of that particular menu.
    this.renderTemplate();

    //toggling the menu filter component.
    this.renderMenuFilter();
  },
  mounted() {
    // this.loadContinueFireData();
    setTimeout(() => {
      if (
        this.localDisplayLang === "ara" &&
        this.continueWatchData &&
        this.continueWatchData.length < 5
      ) {

        document.getElementById("continueWatch-carousel-iw")
          ? (document.getElementById(
              "continueWatch-carousel-iw"
            ).style.textAlign = "-webkit-right")
          : "";
      }
    }, 700);
  },
  methods: {
    getIdValue (str) {
      var newString = str.replace(/[^A-Z0-9]+/ig, "_");
      return newString
    },
    getUserType() {
      if (!this.subscriberid) {
        return "GUEST";
      } else if (this.subscriberid && this.subscriptions.length > 0) {
        return "SUBSCRIBED";
      } else {
        return "REGISTERED";
      }
    },
    renderTemplateUsingDecking () {
      this.screens && this.screens.sections && this.screens.sections.forEach((element) => {
        if(element.sectionType == "FILTER" && element.listType == "CAROUSEL") {
          this.featuredScreen = element;
        } else {
          this.categoryScreens.push(element);
        }
      });      
    },
    renderTemplate() {
      if (this.appConfig.featureEnabled && this.appConfig.featureEnabled.isDeckingEnabled) {
          this.renderTemplateUsingDecking();
          return;
      }

      this.featuredScreen = this.screens.sections[
        this.screens.sections.findIndex((element) => {
          return element.sectionType === "FEATURED";
        })
      ];
      this.categoryScreens = this.screens.sections.filter((element) => {
        return element.sectionType !== "FEATURED";
      });   
    },
    //toggling the menu filter component based on the hasFilter property in each menu object.
    renderMenuFilter() {
      let payload = {};
      if (!this.menu.hasOwnProperty("hasFilter")) {
        payload.state = false;
      } else if (this.menu.hasOwnProperty("hasFilter") && this.menu.hasFilter) {
        payload.state = true;
        payload.menu = this.menu;
      } else if (this.menu === null) {
        payload.state = false;
      } else {
        payload.state = false;
      }
      eventBus.$emit("toggleMenuFilter", payload);
      window.scrollTo(0, 0);
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },

    hoverEffect() {
      this.showHoveredColor = true;
    },

    noHoverEffect() {
      this.showHoveredColor = false;
    },
  },

  beforeDestroy() {
    eventBus.$off("rerender-template");
  },

  components: {
    // ThumbGallery: () => import(/* webpackChunkName: "THUMBGALLERY" */ '@/themeearth/components/dashboard/thumbGallery/ThumbGalleryCarousel.vue'),
    featuredParent: () => import(/* webpackChunkName: "featured" */ "@/components/Carousels/featuredParent.vue"),
    Slider: () => import('@/components/categorySlider/slider.vue'),
    continueSlider: () => import(/* webpackChunkName: "contslider" */ "@/components/categorySlider/continueSlider.vue"),
  },
  // mixins: [FirebaseActions]
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./menuTemplate.scss"
</style>
